<template>
  <div
    v-show="showError"
    class="Error--Wrapper"
  >
    <div class="Error--Message">
      <div class="Container">
        <div class="Columns">
          <div class="Column">
            <h1 class="Error--Title">
              {{ $t(`error.code.${props.error.statusCode}.title`) }}
            </h1>
            <h2 class="Error--Subtitle">
              {{ $t(`error.code.${props.error.statusCode}.message`) }}
            </h2>
            <p class="Error--Code">
              {{
                $t('error.page.statusCode', [props.error.statusCode])
              }}
            </p>
            <p>
              {{ $t(`error.code.${props.error.statusCode}.details`) }}
            </p>
            <p>
              {{ $t('error.page.redirection_1') }}
              <HaButton
                href="/contact"
                target="_blank"
                rel="noreferrer noopener"
                variant="link"
                size="large"
                data-ux="Explore_Error_Contact"
              >
                {{ $t('error.page.redirection_2') }}
                <span class="visually-hidden">
                  {{ $t('accessibility.openNewWindow') }}
                </span>
              </HaButton>
              {{ $t('error.page.redirection_3') }}
            </p>
            <client-only>
              <div class="Error--Buttons">
                <HaButton
                  href="/"
                  variant="outline"
                  size="large"
                  data-ux="Explore_Error_BackToHome"
                >
                  {{ $t('error.page.backToHome') }}
                </HaButton>
                <HaButton
                  size="large"
                  data-ux="Explore_Error_GoBack"
                  @click="$router.go(-1)"
                >
                  {{ $t('error.page.back') }}
                </HaButton>
              </div>
            </client-only>
          </div>
        </div>
      </div>
    </div>
    <div class="Error--Image" />
  </div>
  <div
    v-show="!showError"
    class="error-loading"
  >
    <HaLoader />
    <small>Chargement...</small>
  </div>
</template>

<script setup lang="ts">
import {
  useServerSeoMeta,
  useI18n,
  onBeforeMount,
  useCookies,
  useRouter,
  ref,
  navigateTo
} from '#imports'
import type { NuxtError } from '#app'
import { HaButton } from '#components'
import { isUserConnected } from './services/auth.service'
import HaLoader from './components/HaLoader/HaLoader.vue'
const { cookies } = useCookies()
const router = useRouter()
const i18n = useI18n()
const showError = ref(false)
const props = defineProps<{ error: NuxtError }>()

useServerSeoMeta({
  title: `${i18n.t(`error.code.${props.error.statusCode}.title`)} | HelloAsso`
})

onBeforeMount(async () => {
  if (
    window.location.pathname.includes('/associations/') &&
    isUserConnected(cookies)
  ) {
    // hash vide pour actuel
    setTimeout(
      () => router.replace(window.location.pathname + '#'),
      300
    )
  } else if (window.location.pathname.includes('/associations/')) {
    setTimeout(() => showError.value = true, 0)
    // await navigateTo('/e/recherche?redirect=true')
  } else {
    showError.value = true
  }
})
</script>

<style scoped lang="scss">
.Columns {
  margin-right: calc(-0.5 * var(--gutter));
  margin-left: calc(-0.5 * var(--gutter));

  .Column {
    padding-right: calc(var(--gutter) / 2);
    padding-left: calc(var(--gutter) / 2);

    @include mediaQuery(900) {
      width: 50%;
    }
  }
}

.Error {
  min-height: calc(100dvh - var(--header-height));

  p {
    margin-bottom: $ha-spacing-medium;
    line-height: $ha-line-height-large;

    &:last-of-type {
      margin-bottom: $ha-spacing-big;
    }
  }
}

.Error--Wrapper {
  position: relative;
  width: 100%;
  padding-top: $ha-spacing-giant;

  @include mediaQuery(900) {
    padding-top: $ha-spacing-giant * 2;
    padding-bottom: $ha-spacing-giant * 2;
  }
}

.Error--Title {
  margin-bottom: $ha-spacing-large;
  font-size: $ha-font-size-giant * 1.5; // 48px
  line-height: $ha-line-height-small;
}

.Error--Subtitle {
  margin-bottom: $ha-spacing-big;
  color: var(--ha-color-text-light);
}

.Error--Code {
  color: var(--ha-color-text-lightest);
  font-weight: $ha-font-weight-semibold;
}

.Error--Image {
  width: 100%;
  height: 100vw;
  margin-top: $ha-spacing-giant;
  background: url('https://cdn.helloasso.com/images/explore/illustrations/illustration-abyss.svg')
    no-repeat center left;
  background-size: cover;
  transform: rotate(90deg);

  @include mediaQuery(900) {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    margin-top: 0;
    transform: rotate(0);
  }
}

.Error--Buttons {
  margin-right: -($ha-spacing-tiny);
  margin-left: -($ha-spacing-tiny);

  a,
  button {
    margin: $ha-spacing-tiny;
  }
}

.error-loading {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
  height: 100vh;
  color: var(--hads-color-silver);
  font-size: var(--hads-spacing-300);
}
</style>
