import {
  faPeopleRoof,
  faPersonWalking,
  faSeedling
} from '@fortawesome/pro-solid-svg-icons'
import type { ConfigEnv } from '@/composables/useConfigEnv'
import { SEARCH_INDEX } from '~/domains/search/interfaces/search.interface'
const prefixIndexes = (configEnv: ConfigEnv) => {
  return configEnv.NUXT_ENV_ENVIRONMENT === 'staging'
    ? 'prod'
    : (configEnv.NUXT_ENV_ENVIRONMENT as string)
}

/**
 * Available indexName in Algolia (depends on enviro nment type: dev, rc, prod)
 */
export const getIndexes = (configEnv: ConfigEnv): any => {
  return {
    activities: `${prefixIndexes(configEnv)}_activities`,
    // categories: `${prefixIndexes(configEnv)}_categories`,
    cities: `${prefixIndexes(configEnv)}_cities`,
    content: `${prefixIndexes(configEnv)}_content`,
    internalTags: `${prefixIndexes(configEnv)}_internal_tags`,
    organizations: `${prefixIndexes(configEnv)}_organizations`,
    tags: `${prefixIndexes(configEnv)}_tags`,
    projects: `${prefixIndexes(configEnv)}_projects`,
    partners: `${prefixIndexes(configEnv)}_partners`
  }
}

/**
 * Available tabs (tabs are passed in french via url)
 */
export enum Tabs {
  ALL = 'tout',
  ORGANIZATIONS = 'associations',
  ACTIVITIES = 'activites',
  PROJECTS = 'projets',
}

/**
 * Index from tabs
 */
export const IndexNameFromTab = {
  [Tabs.ORGANIZATIONS]: SEARCH_INDEX.ORGANIZATIONS,
  [Tabs.ACTIVITIES]: SEARCH_INDEX.ACTIVITIES,
  [Tabs.PROJECTS]: SEARCH_INDEX.PROJECTS
}

/**
 * Icons for tabs
 */
export const TabIcons = {
  [Tabs.ORGANIZATIONS]: faPeopleRoof,
  [Tabs.ACTIVITIES]: faPersonWalking,
  [Tabs.PROJECTS]: faSeedling,
  [Tabs.ALL]: {}
}

/**
 * Universes (first level):  the left term matches the url param, the right term matches the algolia param
 */
export enum Categories {
  animaux = 'Animaux',
  'arts--et--culture' = 'Arts & Culture',
  'artisanat--et--cuisine' = 'Artisanat & Cuisine',
  'bien-etre' = 'Bien-être',
  'ecologie--et--environnement' = 'Ecologie & Environnement',
  education = 'Education',
  loisirs = 'Loisirs',
  musique = 'Musique',
  patrimoine = 'Patrimoine',
  'reseaux--pros--et--etudiants' = 'Réseaux pros & étudiants',
  sante = 'Santé',
  'sciences--et--technologie' = 'Sciences & Technologie',
  'solidarite--et--action--sociale' = 'Solidarité et Action sociale',
  spiritualite = 'Spiritualité',
  sport = 'Sport'
}

export enum CategoryKeys {
  animaux ='animaux',
  'arts--et--culture' ='arts--et--culture',
  'artisanat--et--cuisine' ='artisanat--et--cuisine',
  'bien-etre' ='bien-etre',
  'ecologie--et--environnement' ='ecologie--et--environnement',
  education ='education',
  loisirs ='loisirs',
  musique ='musique',
  patrimoine ='patrimoine',
  'reseaux--pros--et--etudiants' ='reseaux--pros--et--etudiants',
  sante ='sante',
  'sciences--et--technologie' ='sciences--et--technologie',
  'solidarite--et--action--sociale' ='solidarite--et--action--sociale',
  spiritualite ='spiritualite',
  sport ='sport',
}

/**
 * Available activity types (passed in french via url)
 * Left side: url version |  right side: algolia version
 */
export enum ActivityTypes {
  atelier = 'Atelier',
  benevolat = 'Bénévolat',
  competition = 'Compétition',
  concert = 'Concert',
  conference = 'Conférence',
  cours = 'Cours',
  culte = 'Culte',
  exposition = 'Exposition',
  festival = 'Festival',
  fete = 'Fête',
  'financement--de--projet' = 'Financement de projet',
  formation = 'Formation',
  jeu = 'Jeu',
  sejour = 'Séjour',
  sortie = 'Sortie',
  spectacle = 'Spectacle'
}

export enum ActivityTypesKeys {
  atelier = 'atelier',
  benevolat = 'benevolat',
  competition = 'competition',
  concert = 'concert',
  conference = 'conference',
  cours = 'cours',
  culte = 'culte',
  exposition = 'exposition',
  festival = 'festival',
  fete = 'fete',
  financement = 'financement--de--projet',
  formation = 'formation',
  jeu = 'jeu',
  sejour = 'sejour',
  sortie = 'sortie',
  spectacle = 'spectacle',
}


/**
 * List of all possible filters in the filters
 */
export enum Filters {
  ACTIVITY_TYPES = 'activity_type',
  CATEGORIES = 'category_tags',
  CITY = 'place_city',
  DATE = 'date',
  LOCATION = 'location',
  DEPARTMENT = 'place_department',
  HA_TAGS = 'ha_tags',
  TAG_LABEL = 'tag_label',
  PRICE = 'price',
  QUERY = 'query',
  REGION = 'place_region',
  TAB = 'tab',
  ZIPCODE = 'place_zipcode',
  BBOX = 'bbox',
  PARTNERS = 'partners'
}

export enum DateOptionsLabel {
  TODAY = 'aujourd\'hui',
  TOMORROW = 'demain',
  NEXT_WEEK_END = 'ce week-end',
  CURRENT_WEEK = 'cette semaine',
  NEXT_WEEK = 'la semaine prochaine',
  CURRENT_MONTH = 'ce mois-ci',
  NEXT_MONTH = 'le mois prochain'
}

export enum DateOptions {
  TODAY = 'aujourd\'hui',
  TOMORROW = 'demain',
  NEXT_WEEK_END = 'ce week-end',
  CURRENT_WEEK = 'cette semaine',
  NEXT_WEEK = 'la semaine prochaine',
  CURRENT_MONTH = 'ce mois-ci',
  NEXT_MONTH = 'le mois prochain'
}

export enum HitModes {
  DEFAULT = 'default',
  CAROUSEL = 'carousel',
  HIT_LIST = 'hitList'
}

// Remark: asyncData function cannot parse components as strings
export enum SeoAvailableComponent {
  AMOUNT_CARD = 'AmountCard',
  CAROUSEL = 'Carousel',
  CAROUSEL_GROUP = 'CarouselGroup',
  CITIES = 'Cities',
  HERO = 'Hero',
  HIT_LIST = 'HitList',
  HOW_IT_WORKS = 'HowItWorks',
  SECTION_TITLE = 'SectionTitle',
  SEO_CONTENT_1 = 'SeoContent1',
  SEO_CONTENT_2 = 'SeoContent2',
  SEO_CONTENT_3 = 'SeoContent3',
  SIGN_UP = 'SignUp',
  SITEMAP = 'Sitemap',
  SOCIALS = 'Socials',
  FAQ = 'Faq'
}

export enum Referrers {
  Arles = 'p_arles',
  Bordeaux = 'rda_bordeaux',
  fncof = 'fncof',
  Gravelines = 'gravelines',
  'Mons-en-Barœul' = 'p_monsenbaroeul',
  Saumur = 'saumur',
  'Seine-Saint-Denis' = 'p_seinesaintdenis',
  Mulhouse = 'p_mulhouse',
  Billère = 'p_billere',
  medecinsdumonde = 'medecinsdumonde',
  Nantes = 'p_nantes',
  'Pyrénées-Atlantiques' = 'p_pyreneesatlantiques',
  Aubervilliers = 'p_aubervilliers',
  'sapeurs-pompiers' = 'fnspf',
  Mérignac = 'p_merignac',
  'Le Kremlin-Bicêtre' = 'p_kremlinbicetre',
  Angers = 'p_angers',
  telethon = 'telethon'
}

export enum HaTag {
  COMITES_DES_FETES = 'comites-des-fetes',
  FNCOF = 'fncof',
  SAPEURS_POMPIERS = 'sapeurs-pompiers',
  MEDECINS_DU_MONDE = 'medecinsdumonde',
  TELETHON = 'telethon'
}
