import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/builder/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/builder/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/builder/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ from "/builder/node_modules/nuxt/dist/app/plugins/debug-hooks.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/builder/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/builder/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/builder/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/builder/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/builder/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builder/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/builder/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NrNkpSIgYI4OqBB5e9KFAy8tdbIhEOn27PAX4N00TWU from "/builder/node_modules/@ha/components-v3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_hnVfinC8XgswT_84dbeYlRnOcZEvjNnPh6TLSYHDGKg from "/builder/node_modules/@ha/components-v3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_Nuw15BRl1VSGFOKJp6JLMp8MOZmDS76HJiyrxgkw2ao from "/builder/node_modules/@ha/components-v3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/builder/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import pluginTracking_client_zf691sn8shmtoVjzZVvekdyMuv2E2Nl79OhSGjm568k from "/builder/node_modules/@ha/components-v3/src/plugins/pluginTracking.client.ts";
import pluginVeeValidate_RGArQHXSM5JnbnUrSdQWfRDQxZtNWbc9xWmmDCmtjX4 from "/builder/node_modules/@ha/components-v3/src/plugins/pluginVeeValidate.js";
import _0_axeptio_client_wNsesK7jZ8L0Ts14xtEaPit_ta9pGxFsywzgSn70Qks from "/builder/src/plugins/0.axeptio.client.ts";
import algolia_client_Xq5JwpTMQlNwQYcizbRxONDgjBFqFWi8vdDQQdjxxZY from "/builder/src/plugins/algolia-client.ts";
import datadog_logger_client_uVCF5JQYoieY84RUf3ZtMBbx2hIazS0Mt45XvuDecfk from "/builder/src/plugins/datadog-logger.client.ts";
import error_handler_rLaONCpBi_o3fpyg_8YksOhfcjAcQ26_0of0GaTmX3o from "/builder/src/plugins/error-handler.ts";
import gtm_client_uJb6trLND7OhTa_wrtMsRMu0sysgwaM3Y_4f0K4tGHg from "/builder/src/plugins/gtm.client.ts";
import stores_pinia_1AS907Nk9OgtKA2v6M_f2ExoEXC_7moYg2x9ksVuM4s from "/builder/src/plugins/stores-pinia.ts";
import ssg_detect_OLitT21o3swuOMJgjno2n70Xfnkg0fo0rR2dP4C3XSg from "/builder/node_modules/@ha/components-v3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NrNkpSIgYI4OqBB5e9KFAy8tdbIhEOn27PAX4N00TWU,
  route_locale_detect_hnVfinC8XgswT_84dbeYlRnOcZEvjNnPh6TLSYHDGKg,
  i18n_Nuw15BRl1VSGFOKJp6JLMp8MOZmDS76HJiyrxgkw2ao,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  pluginTracking_client_zf691sn8shmtoVjzZVvekdyMuv2E2Nl79OhSGjm568k,
  pluginVeeValidate_RGArQHXSM5JnbnUrSdQWfRDQxZtNWbc9xWmmDCmtjX4,
  _0_axeptio_client_wNsesK7jZ8L0Ts14xtEaPit_ta9pGxFsywzgSn70Qks,
  algolia_client_Xq5JwpTMQlNwQYcizbRxONDgjBFqFWi8vdDQQdjxxZY,
  datadog_logger_client_uVCF5JQYoieY84RUf3ZtMBbx2hIazS0Mt45XvuDecfk,
  error_handler_rLaONCpBi_o3fpyg_8YksOhfcjAcQ26_0of0GaTmX3o,
  gtm_client_uJb6trLND7OhTa_wrtMsRMu0sysgwaM3Y_4f0K4tGHg,
  stores_pinia_1AS907Nk9OgtKA2v6M_f2ExoEXC_7moYg2x9ksVuM4s,
  ssg_detect_OLitT21o3swuOMJgjno2n70Xfnkg0fo0rR2dP4C3XSg
]