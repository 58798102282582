import { invert, isEqual } from 'lodash-es'
import { haMoment, functions } from '@ha/helpers'
import {
  ActivityTypes,
  type DateOptions,
  IndexNameFromTab
} from '@/helpers'
import type { Form, Hit, Results } from '@/models'
import { PageType } from '~/domains/thematic/interfaces/page_type.interface'

export const isValidJSON = (str) => {
  try {
      JSON.parse(str);
      return true;
  } catch (e) {
      return false;
  }
}

/**
 * -------------------------------------------------------------------------------
 */

/**
 * Format a file size to be human readable.
 * e.g. humanFileSize(12345) => '12.35 Ko'
 * @param {number} fileSize in bytes
 * @returns {string}
 */
export function humanFileSize(fileSize: number): string {
  const sizeMultiples = ['o', 'Ko', 'Mo', 'Go']
  const i = Math.floor(Math.log(fileSize) / Math.log(1000))
  return `${Math.round((fileSize / Math.pow(1000, i) + Number.EPSILON) * 100) / 100} ${sizeMultiples[i]}`
}

/**
 * Retrieves tab from algolia indexName
 * @param {string} indexName
 * @returns {string} tab
 */
export function tabFromIndexName(indexName: string): string {
  return invert(IndexNameFromTab)[indexName]
}
/**
 * Check if given object is not empty
 * @param {object}
 * @returns {boolean}
 */
export const isNotEmptyObject = (obj: object): boolean =>
  Boolean(Object.keys(obj).length > 0)

/**
 * Converts a price url query to a price array
 * @param {string} slug query param value:
 * @returns {array} with min (and max if any) values
 */
export const extractPrices = function (slug: string) {
  if (!slug) {
    return null
  }

  // allowed cases
  // 20--30
  // 20.00--30.00
  // 20,00--30,00
  // 30--20 is not tolerated
  let regex = slug.match(
    /((\d+[\\.|,]\d{1,3}|\d+)(--)(\d+[\\.|,]\d{1,2}|\d+))/
  )

  if (regex?.[3]) {
    const minVal = parseInt(regex[2], 10)
    const maxVal = parseInt(regex[4], 10)
    return [minVal, maxVal]
  }
  // allowed cases
  // 20
  // 20.00
  // 20,00
  regex = slug.match(/(\d+[\\.|,]\d{1,3}|\d+)/)
  if (regex == null) {
    // need this, otherwise it throw an error
    return null
  }
  if (regex[0]) {
    return [parseInt(regex[0], 10)]
  }

  return null
}

/**
 * Converts a price url query to a algolia price filter string
 * @param {string} value url param value
 * 3 formats are accepted:
 * - 'gratuit' free value
 * - '20--30' range
 * - '20' min only
 * @returns {string} algolia price query string or null if cannot parse price
 */
export const getAlgoliaPriceFilter = function (
  value: string | string[]
): object | null {
  // Only one price range is supported
  const price = Array.isArray(value) ? value[0] : value

  // Free case
  if (price === 'gratuit') {
    return { min_price: { eq: 0 } }
  }

  // Value / Range case
  const pricesInCents = extractPrices(price)

  if (pricesInCents?.[1]) {
    return {
      and: [
        {
          min_price: {
            between: [pricesInCents[0] * 100, pricesInCents[1] * 100]
          }
        },
        {
          max_price: {
            between: [pricesInCents[0] * 100, pricesInCents[1] * 100]
          }
        }
      ]
    }
  }
  // pricesIncents is an array of number
  if (pricesInCents?.length) {
    return { min_price: { gte: (value as unknown as number) * 100 } }
  }

  return null
}

/**
 * Converts a date slug from url to a algolia date filter string
 * @param {string} value query param value
 * @returns {string} algolia price query string or null if cannot parse slug
 */

export type ToTimeStampQuery = {
  [key in DateOptions]: object
}

export const toTimeStampQuery: ToTimeStampQuery = {
  "aujourd'hui": {
    between: [
      haMoment().startOf('day').unix(),
      haMoment().endOf('day').unix()
    ]
  },
  demain: {
    between: [
      haMoment().startOf('day').add(1, 'day').unix(),
      haMoment().endOf('day').add(1, 'day').unix()
    ]
  },
  'ce week-end': {
    between: [
      haMoment()
        .endOf('week')
        .subtract(2, 'days')
        .subtract(6, 'hours')
        .unix(),
      haMoment().endOf('week').unix()
    ]
  },
  'cette semaine': {
    between: [
      haMoment().startOf('week').unix(),
      haMoment().endOf('week').unix()
    ]
  },
  'la semaine prochaine': {
    between: [
      haMoment().startOf('week').add(1, 'week').unix(),
      haMoment().endOf('week').add(1, 'week').unix()
    ]
  },
  'ce mois-ci': {
    between: [
      haMoment().startOf('month').unix(),
      haMoment().endOf('month').unix()
    ]
  },
  'le mois prochain': {
    between: [
      haMoment().add(1, 'months').startOf('month').unix(),
      haMoment().add(1, 'months').endOf('month').unix()
    ]
  }
}

const isLocationMicroDataCompliant = (form: any): boolean => {
  return form?.place_city && form?.place_country
}

/**
 * Forge jsonId microdata from Event and Organization info
 * @param {Object} form hit informations
 * @returns {Array} a forged jsonld array
 */
export const getMicroData = (form: any, configEnv): object | null => {
  // We want to only fetch Events with a start date
  // Otherwise, Google will return an error
  if (form.form_type === 'Event' && form.start_date && isLocationMicroDataCompliant(form)) {
    return {
      '@context': 'http://schema.org',
      '@graph': getEventMicrodata(form, configEnv)
    }
  }

  return null
}

const getEventMicrodata = (form: any, configEnv): object => {
  let urlAsso = form.url

  urlAsso &&= urlAsso.split('/evenements', 1)[0]

  const json = {
    '@type': 'Event',
    id: form.url,
    url: form.url,
    logo:
      form.banner ||
      form.logo ||
      `${configEnv.NUXT_ENV_BASE_URL}/_ex/logo-helloasso.svg`,
    name: form.name,
    description: form.description,
    organizer: {
      '@type': 'Organization',
      name: form.organization_name,
      url: urlAsso
    },
    location: getPlaceMicrodata(form),
    startDate: haMoment(form.start_date).format('YYYY/MM/DD'),
    endDate: null as any
  }

  if (form.end_date) {
    json.endDate = haMoment(form.end_date).format('YYYY/MM/DD')
  }

  return json
}

const getPlaceMicrodata = (form: Hit) => {
  if (isLocationMicroDataCompliant(form)) {
    return {
      '@type': 'Place',
      name: form.place_name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: form.place_address,
        addressLocality: form.place_city,
        postalCode: form.place_zipcode,
        addressCountry: form.place_country
      }
    }
  }
}

/**
 * Creates jsonld seo structure
 * @param {String} region
 * @param {String} department
 * @param {String} city
 * @param {String} category
 */
export function getSeoCleanedData(
  slug: string
): keyof typeof ActivityTypes | null {
  return (
    (typeof slug === 'string' &&
      functions
        .deburr(slug?.toLowerCase())
        .replace(/['’]/g, '---')
        .replace(/\s/g, '--')
        .replace('&', 'et')) ||
    null
  )
}

/**
 * Takes a number and returns a formatted string
 * @example 'Fr-FR': 3141592 => '3 141 592'
 * @param {Number} value
 * @returns {String}
 */
export function formattedThousands(value: number): string {
  return new Intl.NumberFormat().format(value)
}

interface amountParams {
  amount: number
  areCents?: boolean
}

/**
 * Takes a number (in Centimes) and returns a locale-formatted string (in Euros).
 * @example 'Fr-FR': 3141.596 => '3 141.60 €'
 * @param {Object} params
 * @param {Number|0} params.amount - Mandatory field
 * @param {Boolean|false} params.areCents
 * @param {String} locale
 * @param {Intl.NumberFormatOptions} [options={}] // if want to set min or maximumFractionDigits, set both not just one
 * @returns {String} Locale-formatted string (in Euros)
 */

export function amountToLocaleFormattedEuros(
  params: amountParams,
  locale: string = 'fr-FR',
  options: Intl.NumberFormatOptions = {}
): string {
  // eslint-disable-next-line prefer-const
  let { amount, areCents } = Object.assign(
    {
      amount: 0,
      areCents: false
    },
    params
  )

  // Convert centimes to Euros :
  if (areCents) {
    amount /= 100
  }

  const numberDigits = amount % 1 !== 0 ? 2 : 0

  // BUG - with local in Alpin Docker
  // const baseOptions: Intl.NumberFormatOptions = {
  //   // Check if amount is decimal or integer, we want two digits after comma only for decimal
  //   minimumFractionDigits: numberDigits,
  //   maximumFractionDigits: numberDigits,
  //   style: 'currency',
  //   currency: 'EUR'
  // }

  // const mergeOptions = Object.assign(baseOptions, options)

  // // To prevent a result with min > max if we forget to set min && max in options param
  // if (
  //   mergeOptions.minimumFractionDigits >
  //   mergeOptions.maximumFractionDigits
  // ) {
  //   mergeOptions.minimumFractionDigits =
  //     mergeOptions.maximumFractionDigits
  // }

  return amount.toFixed(numberDigits) + '€'
}

/**
 * Check if a text starts by a vowel based on first letter
 * @param {String} firstLetter - Length should be {1,}
 * @returns {Boolean}
 */
export function isFirstLetterAVowel(firstLetter: string): boolean {
  firstLetter &&= functions.deburr(firstLetter.charAt(0))

  return /^[aeiouy]$/i.test(firstLetter)
}

/**
 * Check if an activity type is feminine or not
 * @param {Object} filters
 * @returns {Boolean}
 */
export function isFeminineActivityType(type: string): boolean {
  const seoCleanedData: keyof typeof ActivityTypes | null =
    getSeoCleanedData(type)
  if (seoCleanedData) {
    return [
      ActivityTypes.competition,
      ActivityTypes.conference,
      ActivityTypes.exposition,
      ActivityTypes.fete,
      ActivityTypes.formation,
      ActivityTypes.sortie
    ].includes(ActivityTypes[seoCleanedData])
  }
  return false
}

/**
 * Provides a crossed page type depending on (url params) context
 * @param {Object} params
 * @returns {PageType}
 */
export function getCrossedPageActivityType(params: object) {
  const types = Object.keys(params)

  let pageType
  if (
    isEqual(types, [
      'place_region',
      'place_department',
      'place_city',
      'activity_type'
    ])
  ) {
    pageType = PageType.ACTIVITY_TYPE_IN_CITY
  } else if (
    isEqual(types, [
      'place_region',
      'place_department',
      'activity_type'
    ])
  ) {
    pageType = PageType.ACTIVITY_TYPE_IN_DEPARTMENT
  } else if (isEqual(types, ['place_region', 'activity_type'])) {
    pageType = PageType.ACTIVITY_TYPE_IN_REGION
  } else if (isEqual(types, ['activity_type'])) {
    pageType = PageType.ACTIVITY_TYPE
  }

  return pageType
}

/**
 * Provides a crossed page type depending on (url params) context
 * @param {Object} params
 * @returns {PageType}
 */
export function getCrossedPageCategoryTags(params: object) {
  const types = Object.keys(params)

  let pageType
  if (
    isEqual(types, [
      'place_region',
      'place_department',
      'place_city',
      'category_tags'
    ])
  ) {
    pageType = PageType.CATEGORY_IN_CITY
  } else if (
    isEqual(types, [
      'place_region',
      'place_department',
      'category_tags'
    ])
  ) {
    pageType = PageType.CATEGORY_IN_DEPARTMENT
  } else if (isEqual(types, ['place_region', 'category_tags'])) {
    pageType = PageType.CATEGORY_IN_REGION
  } else if (isEqual(types, ['category_tags'])) {
    pageType = PageType.CATEGORY
  }

  return pageType
}

/**
 * Convert searchable list into an algolia standard queries list
 * @param {Object} filter a filter matching a similar syntax
 * @param {regex} regex
 * @returns {Boolean}
 */
/* export function doesAlgoliaFilterMatches (filter, regex) {
  const key = Object.keys(filter)

  if (
    ['eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'between', 'in'].includes(key) &&
    typeof filter[key] === 'string'
  ) {
    return filter[key].match(regex)
  }

  return false
} */

/**
 * Use Algolia to get normalized location: place_city, place_department, place_region
 * @param {Array} results a set of Algolia results
 * @param {Object} params
 * @returns {Object} location with place_region, place_department, place_city
 */
export function getNormalizedLocation(results: Results, params: any) {
  let normalizedLocation: any
  results.forEach((result) => {
    if (result?.nbHits) {
      // Take the first result matching location
      const [{ place_region, place_department, place_city }] =
        result.hits
      normalizedLocation = { place_region }

      if (params.place_department) {
        normalizedLocation.place_department = place_department
      }

      if (params.place_city) {
        normalizedLocation.place_city = place_city
      }
    }
  })
  return normalizedLocation
}

/**
 * Capitalize the first letter of a string, replace ._capitalize and ._upperFirst
 * @param {String} value
 * @returns {String | Null}
 */
export const capitalizeFirstLetter = (
  value: string
): string | null =>
  value.length > 0 ? value[0].toUpperCase() + value.slice(1) : null

/**
 * Return pageType for thematic page
 * @param {Object} route
 * @returns {String | Null}
 */
export function getPageType({ path = '', params = {} }: any): {
  pageType: string | null
} {
  function isNonEmpty(
    value: string | undefined | null | string[]
  ): boolean {
    return (
      (typeof value === 'string' || Array.isArray(value)) &&
      value.length > 0
    )
  }

  // Keep this order to respect route order
  // ( city -> department -> department -> region)
  const generatePageType: any = {
    cat: () => getCrossedPageCategoryTags(params),
    act: () => getCrossedPageActivityType(params),
    evt: () => {
      if (isNonEmpty(params.ha_tags)) {
        return PageType.HA_TAG
      }
    },
    ville: () => {
      if (
        isNonEmpty(params.place_region) &&
        isNonEmpty(params.place_department) &&
        isNonEmpty(params.place_city)
      ) {
        return PageType.CITY
      }
    },
    dep: () => {
      if (
        isNonEmpty(params.place_region) &&
        isNonEmpty(params.place_department)
      ) {
        return PageType.DEPARTMENT
      }
    },
    reg: () => {
      if (isNonEmpty(params.place_region)) {
        return PageType.REGION
      }
    }
  }

  // URL is splitted and get the element between the last slashes.
  const routeElements = path.split('/')
  const pageTypeToGenerate: any =
    routeElements[routeElements.length - 2]

  if (
    Object.prototype.hasOwnProperty.call(
      generatePageType,
      pageTypeToGenerate
    )
  ) {
    const pageType = generatePageType[pageTypeToGenerate]()

    if (typeof pageType === 'string') {
      return { pageType }
    }
  }

  return { pageType: null }
}

export const createHTMLElementFromString = (
  str: string
): ChildNode | null => {
  const div = document.createElement('div')
  div.innerHTML = str.trim()

  return div.firstChild
}

export const getKeyByValue = (
  obj: object,
  value: string
): string | null | undefined => {
  if (obj?.constructor === Object) {
    return Object.keys(obj).find(
      (key) => obj[key as keyof object] === value
    )
  }
  return null
}

export function spaceNumberEachThousand(value: number): string {
  return new Intl.NumberFormat('fr-FR')
    .format(value)
    .replaceAll(',', ' ')
}

export const resizedSrc = (
  src: string,
  { width, height }: { width: number; height: number }
): string | undefined => {
  if (src?.length > 0 && !isNaN(width) && !isNaN(height)) {
    const separator = src.includes('?') ? '&' : '?'
    const resize = `resize=fill:${width}:${height}`

    return src + separator + resize
  }
}

// Remove widgets urls froms organization forms
export function formatOrganizationForms(forms: Form[]): Form[] {
  const newForms = forms.map((form) => {
    const newEntries = Object.entries(form).filter(
      (value) => !value[0].startsWith('widget')
    )
    return Object.fromEntries(newEntries) as Form
  })
  return newForms
}
