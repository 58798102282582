import { defineNuxtPlugin, useRuntimeConfig, useLogger } from '#imports'
import type { SessionLoggerConfig, ClientLoggerConfig } from '@ha/components-v3/src/composables/useLogger'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const {
    DD_RUM_CLIENT_TOKEN_EXPLORE,
    DD_RUM_APP_ID_EXPLORE,
    DD_ENV,
    DD_SITE,
    DD_SERVICE_EXPLORE,
    DD_RUM_SESSION_SAMPLE_RATE_EXPLORE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE_EXPLORE,
    DD_NUXT_ENV_TIMESTAMP,
  } = config.public as Record<string, string>

  const { setClientLogger, setSessionLogger } = useLogger()

  const clientLoggerConfig: ClientLoggerConfig = {
    clientToken: DD_RUM_CLIENT_TOKEN_EXPLORE,
    site: DD_SITE,
    sampleRate: DD_RUM_SESSION_SAMPLE_RATE_EXPLORE
      ? parseInt(DD_RUM_SESSION_SAMPLE_RATE_EXPLORE)
      : null,
    env: DD_ENV,
    service: DD_SERVICE_EXPLORE
  }

    const sessionLoggerConfig: SessionLoggerConfig = {
      applicationId: DD_RUM_APP_ID_EXPLORE,
      clientToken: DD_RUM_CLIENT_TOKEN_EXPLORE,
      site: DD_SITE,
      service: DD_SERVICE_EXPLORE,
      env: DD_ENV,
      sessionSampleRate: DD_RUM_SESSION_SAMPLE_RATE_EXPLORE
        ? parseInt(DD_RUM_SESSION_SAMPLE_RATE_EXPLORE)
        : 0,
      sessionReplaySampleRate: DD_RUM_SESSION_REPLAY_SAMPLE_RATE_EXPLORE
        ? parseInt(DD_RUM_SESSION_REPLAY_SAMPLE_RATE_EXPLORE)
        : 0,
      version: DD_NUXT_ENV_TIMESTAMP,
    }

  setClientLogger(clientLoggerConfig)
  setSessionLogger(sessionLoggerConfig)
})
