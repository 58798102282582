
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as hadspDoRRPmPGO7JjovnRIMiFbcug55E3yj229hZnZ70KiwMeta } from "/builder/src/domains/design_system/views/hads.vue?macro=true";
import { default as adminLztmGJ1B15CE3FV1aGBkV6_mJzlIyCiZym0lC4rpSIMMeta } from "/builder/src/domains/association/views/admin.vue?macro=true";
import { default as associationbkhprBQ1paQoRdiyg0_45yCm02EUg0djQMjvdy0Kr2eDgMeta } from "/builder/src/domains/association/views/association.vue?macro=true";
import { default as allZi8Um6qYl2hk7i0Wu9uRqa2Cz1CZEuprqYU5RmFs2isMeta } from "/builder/src/domains/search/views/search/all.vue?macro=true";
import { default as tab0rqMsFzdNPL5JW3__4h_451zDUx837U1t9g6O3T6aqXDQMeta } from "/builder/src/domains/search/views/search/tab.vue?macro=true";
import { default as searchnUQs_45QyCZ1EAvvHDuH7SM7p_4568EjGljGFNHzkfhxIOcMeta } from "/builder/src/domains/search/views/search.vue?macro=true";
import { default as indexFEDlT4UCTR_45SDfSlbQykHW6rlCGVmGrm6mar55_qe5IMeta } from "/builder/src/domains/search/views/widget/index.vue?macro=true";
import { default as createTegZD7PcAr__3VvYtiqVBj8xYylJqb2W3XkuJ_45zgbx4Meta } from "/builder/src/domains/search/views/widget/create.vue?macro=true";
import { default as thematic1gNvexHmbVTaaVt6viZcGiynAgY64atoGfwZKBZ7VxgMeta } from "/builder/src/domains/thematic/views/thematic.vue?macro=true";
import { default as legacy_45homeM0JSYMCf6b0kPZY8qlmznVkTVlgGsSWl60F4GoovMnsMeta } from "/builder/src/domains/home/views/legacy-home.vue?macro=true";
import { default as next_45homen_jx_OKExKCZIc_459Yq5pLRHQ_0GQLHmqnPda5jiGSkkMeta } from "/builder/src/domains/home/views/next-home.vue?macro=true";
export default [
  {
    name: "hads",
    path: "/hads",
    component: () => import("/builder/src/domains/design_system/views/hads.vue")
  },
  {
    name: "association_admin",
    path: "/associations/:organization/administration/ma-page-publique",
    meta: {"middleware":["secure-iframe","fetch-organization-page"]},
    component: () => import("/builder/src/domains/association/views/admin.vue")
  },
  {
    name: "association",
    path: "/associations/:organization",
    meta: {"middleware":["secure-iframe","fetch-organization-page"]},
    component: () => import("/builder/src/domains/association/views/association.vue")
  },
  {
    name: "search",
    path: "/e/recherche",
    meta: {"middleware":["redirect-search"]},
    component: () => import("/builder/src/domains/search/views/search.vue"),
    children: [
  {
    name: "search_home",
    path: "",
    component: () => import("/builder/src/domains/search/views/search/all.vue")
  },
  {
    name: "search_tab",
    path: ":search_tab",
    component: () => import("/builder/src/domains/search/views/search/tab.vue")
  }
]
  },
  {
    name: "widget",
    path: "/e/widget/:widget_type?/:search_tab?",
    meta: {"layout":"widget","middleware":["redirect-widget"]},
    component: () => import("/builder/src/domains/search/views/widget/index.vue")
  },
  {
    name: "create_widget",
    path: "/e/creer-mon-widget/:search_tab?",
    component: () => import("/builder/src/domains/search/views/widget/create.vue")
  },
  {
    name: "thematic_city_category_tags",
    path: "/e/reg/:place_region/dep/:place_department/ville/:place_city/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_city_activity_type",
    path: "/e/reg/:place_region/dep/:place_department/ville/:place_city/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_city",
    path: "/e/reg/:place_region/dep/:place_department/ville/:place_city",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_department_category_tags",
    path: "/e/reg/:place_region/dep/:place_department/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_department_activity_type",
    path: "/e/reg/:place_region/dep/:place_department/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_department",
    path: "/e/reg/:place_region/dep/:place_department",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_region_category_tags",
    path: "/e/reg/:place_region/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_region_activity_type",
    path: "/e/reg/:place_region/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_region",
    path: "/e/reg/:place_region",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_event",
    path: "/e/evt/:ha_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_activity_type",
    path: "/e/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "thematic_category_tags",
    path: "/e/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/builder/src/domains/thematic/views/thematic.vue")
  },
  {
    name: "home",
    path: "/",
    meta: {"layout":"homepage","middleware":["secure-iframe"]},
    component: () => import("/builder/src/domains/home/views/legacy-home.vue")
  },
  {
    name: "next-home",
    path: "/next-homepage",
    meta: {"layout":"homepage","middleware":["secure-iframe"]},
    component: () => import("/builder/src/domains/home/views/next-home.vue")
  }
]